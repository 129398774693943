import * as R from 'ramda'
import { useState, useMemo, useEffect } from 'react'

import LoadingScreen from 'components/shared/loading-screen'
import useProducts from 'hooks/use-products'
import useCurrentUser from 'hooks/use-current-user'
import { mapWithMetadata } from 'utilities/list'
import { ProductSelectAnalytics } from './analytics'
import BillingPeriodSelect, { BILLING_PERIODS } from './billing-period-select'
import Product from './product'
import s from './styles.module.css'
import { useExperimentSplit } from 'utilities/experiment/context'

const ProductSelectVolumeDiscount = ({
  onSelect,
  currentProductId,
  currentCallsPerWeek,
  currentBillingPeriodInMonths,
  coupon,
  canSelectCurrent,
  discountStyle,
  analyticsPageName,
  getIsLoadingByProductId = R.always(false),
  billingPeriod: initialBillingPeriod,
  hideRecommendedBorder = false,
  excludeLiveVideo = false,
  isFreeTrial,
  split = 'A',
}) => {
  const currentUserPayload = useCurrentUser()
  const me = currentUserPayload.data?.me
  const clientId = me?.user?.client?.id
  const coachId = me?.user?.client?.coach?.id

  const useSplit = useExperimentSplit('nutritionUpgrade')
  const nutritionUpgradeSplit = split || useSplit

  const productsPayload = useProducts({
    version:
      nutritionUpgradeSplit && nutritionUpgradeSplit !== 'A' ? 4 : undefined,
    excludeLiveVideo,
    priceVersions:
      nutritionUpgradeSplit === 'B'
        ? [8]
        : nutritionUpgradeSplit === 'C'
        ? [9]
        : undefined,
  })
  const products = useMemo(
    () => productsPayload.data?.products || [],
    [productsPayload.data?.products]
  )

  const currentProduct = products.find(p => p.id === currentProductId)

  const getInitialBillingPeriod = () => {
    if (initialBillingPeriod) return initialBillingPeriod
    if (currentBillingPeriodInMonths) return currentBillingPeriodInMonths

    return BILLING_PERIODS[0].value
  }

  const [billingPeriod, _setBillingPeriod] = useState(getInitialBillingPeriod())

  const setBillingPeriod = newBillingPeriod => {
    _setBillingPeriod(newBillingPeriod)

    ProductSelectAnalytics.onSelectBillingPeriod({
      analyticsPageName,
      billingPeriodInMonths: newBillingPeriod,
      clientId,
      coachId,
    })
  }

  const defaultCallsPerWeek = useMemo(() => {
    if (nutritionUpgradeSplit && nutritionUpgradeSplit !== 'A') {
      return 1
    }
    return 2
  }, [nutritionUpgradeSplit])

  const defaultLivePlusProduct = useMemo(() => {
    return (
      products.find(
        p =>
          p.group === currentProduct?.group &&
          p.callsPerWeek === defaultCallsPerWeek
      ) || products[2]
    )
  }, [products, currentProduct?.group, defaultCallsPerWeek])

  const [activeLivePlusProduct, setActiveLivePlusProduct] = useState(
    defaultLivePlusProduct
  )

  useEffect(() => {
    if (defaultLivePlusProduct && !activeLivePlusProduct) {
      setActiveLivePlusProduct(defaultLivePlusProduct)
    }
  }, [defaultLivePlusProduct, activeLivePlusProduct])

  const hasPrevLivePlusProduct = activeLivePlusProduct?.callsPerWeek > 1
  const hasNextLivePlusProduct = activeLivePlusProduct?.callsPerWeek < 5

  const selectPrevLivePlusProduct = () => {
    if (!hasPrevLivePlusProduct) return

    // const prevIdx = R.indexOf(activeLivePlusProduct, products) - 1
    const prevProduct = products.find(
      p =>
        p.group === currentProduct?.group &&
        p.callsPerWeek === activeLivePlusProduct?.callsPerWeek - 1
    )
    setActiveLivePlusProduct(prevProduct)
  }

  const selectNextLivePlusProduct = () => {
    if (!hasNextLivePlusProduct) return

    // const nextIdx = R.indexOf(activeLivePlusProduct, products) + 1
    const nextProduct = products.find(
      p =>
        p.group === currentProduct?.group &&
        p.callsPerWeek === activeLivePlusProduct?.callsPerWeek + 1
    )
    setActiveLivePlusProduct(nextProduct)
  }

  const shownProducts = useMemo(() => {
    return R.reject(R.isNil, [
      ...R.take(
        (nutritionUpgradeSplit === 'B' || nutritionUpgradeSplit === 'C') &&
          !excludeLiveVideo
          ? 3
          : 2,
        products
      ),
      activeLivePlusProduct || defaultLivePlusProduct,
    ])
  }, [
    nutritionUpgradeSplit,
    excludeLiveVideo,
    products,
    activeLivePlusProduct,
    defaultLivePlusProduct,
  ])

  if (productsPayload.loading) {
    return <LoadingScreen />
  }

  return (
    <div className={s.container}>
      <div className={s.billingPeriods}>
        <BillingPeriodSelect
          value={billingPeriod}
          set={setBillingPeriod}
          discountStyle={discountStyle}
          analyticsPageName={analyticsPageName}
        />
      </div>
      <div className={s.products}>
        {mapWithMetadata((product, { index, isLast }) => {
          const handleSelect = price => {
            onSelect(product, price)

            ProductSelectAnalytics.onSelectPlan({
              analyticsPageName,
              clientId,
              coachId,
              billingPeriodInMonths: price.billingPeriodInMonths,
              callsPerWeek: product.callsPerWeek,
              productId: product.id,
            })
          }

          return (
            <div key={index} className={s.product}>
              <Product
                product={product}
                onSelect={handleSelect}
                hasPrevLivePlusProduct={hasPrevLivePlusProduct}
                hasNextLivePlusProduct={hasNextLivePlusProduct}
                selectPrevLivePlusProduct={selectPrevLivePlusProduct}
                selectNextLivePlusProduct={selectNextLivePlusProduct}
                isCurrentCallsPerWeek={
                  currentCallsPerWeek === product.callsPerWeek
                }
                currentBillingPeriodInMonths={currentBillingPeriodInMonths}
                showRecommended={!currentBillingPeriodInMonths && !isLast}
                canSelectCurrent={canSelectCurrent}
                coupon={coupon}
                showCallFrequencySelect={isLast && !excludeLiveVideo}
                hideRecommendedBorder={hideRecommendedBorder}
                billingPeriodInMonths={billingPeriod}
                isFreeTrial={isFreeTrial}
                isCurrent={
                  currentProductId === product.id &&
                  currentBillingPeriodInMonths === billingPeriod
                }
              />
            </div>
          )
        }, shownProducts)}
      </div>
    </div>
  )
}

export default ProductSelectVolumeDiscount
